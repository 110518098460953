<template>
    <div class="cover">
        <nav class="fh5co-nav" role="navigation">
            <div class="container">
                <div class="row">
                <div class="col-xs-12 text-center">
                    <div id="fh5co-logo"><a href="index.html">Wedding<strong>.</strong></a></div>
                </div>
                </div>
            </div>
        </nav>

        <header v-if="client == null" id="fh5co-header" class="fh5co-cover img-fluid mb-5" alt="Responsive image" role="banner" v-bind:style="{ 'background-image': 'url(https://admin.itsyourdayofficial.com/foto_cover/'+client.foto_cover+')' }" data-stellar-background-ratio="0.5">
            <div class="overlay"></div>
            <div class="container">
                <div class="row">
                <div class="col-md-8 col-md-offset-2 text-center">
                    <div class="display-t">
                    <div class="display-tc animate-box" data-animate-effect="fadeIn">
                        <h1 v-if="client.hajat == 'wanita'">{{client.nama_wanita}} &amp; {{client.nama_pria}}</h1>
                        <h1 v-if="client.hajat == 'pria'">{{client.nama_pria}} &amp; {{client.nama_wanita}}</h1>
                        <h2>Kepada Bapak/Ibu/Saudara/i</h2>
                        <!-- <h3>{{guest.nama}}</h3> -->
                        <h6>Tanpa mengurangi rasa hormat, Kami mengundang Anda untuk hadir diacara pernikahan Kami</h6>
                        <!-- <p><a href="#" class="btn btn-outline-default btn-sm">Buka Undangan</a></p> -->
                        <router-link class="btn btn-outline-default btn-sm" :to="{ name: 'Home', params: {slug: guest.url}}">
                            Buka Undangan
                        </router-link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </header>
        <header v-else id="fh5co-header" class="fh5co-cover img-fluid mb-5" alt="Responsive image" role="banner" v-bind:style="{ 'background-image': 'url(https://admin.itsyourdayofficial.com/foto_cover/'+client.foto_cover+')' }" data-stellar-background-ratio="0.5">
            <div class="overlay"></div>
            <div class="container">
                <div class="row">
                <div class="col-md-8 col-md-offset-2 text-center">
                    <div class="display-t">
                    <div class="display-tc animate-box" data-animate-effect="fadeIn">
                        <h1 v-if="client.hajat == 'wanita'">{{client.nama_wanita}} &amp; {{client.nama_pria}}</h1>
                        <h1 v-if="client.hajat == 'pria'">{{client.nama_pria}} &amp; {{client.nama_wanita}}</h1>
                        <h2>Kepada Bapak/Ibu/Saudara/i</h2>
                        <h3>{{guest.nama}}</h3>
                        <h6>Tanpa mengurangi rasa hormat, Kami mengundang Anda untuk hadir diacara pernikahan Kami</h6>
                        <!-- <p><a href="#" class="btn btn-outline-default btn-sm">Buka Undangan</a></p> -->
                        <router-link class="btn btn-outline-default btn-sm" :to="{ name: 'Home', params: {url: client.slug}, query: {to:guest.url}}">
                            Buka Undangan
                        </router-link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
export default {
    data() {
    return{
            client: {},
            guest: {}
        }
    },
    mounted(){
        if (localStorage.getItem('reloaded')) {
            // The page was just reloaded. Clear the value from local storage
            // so that it will reload the next time this page is visited.
            localStorage.removeItem('reloaded');
        } else {
            // Set a flag so that we know not to reload the page twice.
            localStorage.setItem('reloaded', '1');
            location.reload();
        }
        this.$api.get('/' + this.$route.params.slug, {
        params: {
            to: this.$route.query.to
            }
        })
        .then(response => { 
            this.client = response.data.data;
            this.guest = response.data.data;
            // console.log(response.data);
        })
        .catch(error => {
            console.log(error.response.data);
        });
    }
}
</script>