<template>
    <div id="fh5co-gallery" class="fh5co-section-gray">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
            <span>Our Memories</span>
            <h2 style="font-family: 'Oleo Script', Sans-serif;">{{ config.language === 1 ? 'Photo Gallery' : 'Galeri Foto' }}</h2>
          </div>
        </div>
        <div class="row justify-content-center row-bottom-padded-md">
            <!-- <div class="col-md-12">
                <split-carousel :item-width="245" :height="420" >
                    <split-carousel-item  v-for="item in gallery" :key="item.id">
                        <img v-img:group :src="'https://admin.itsyourdayofficial.com/gallery/' + item.file" class="img-thumbnail" alt="">
                    </split-carousel-item>
                </split-carousel>
            </div> -->
            <div class="col-md-12 animate-box">
                <div v-for="item in gallery" :key="item.id" class="col-lg-4 col-md-8 col-sm-10">
                    <div class="text-center" style="margin-bottom: 10px;" >
                        <img v-img:group :src="'https://admin.itsyourdayofficial.com/gallery/' + item.file" style="border-radius: 5px; width:100%;" class="img-fluid img-thumbnail" alt="...">
                    </div>
                </div>
            </div>
        </div>  
      </div>
    </div>
</template>

<script>
import { SplitCarousel, SplitCarouselItem } from "vue-split-carousel";
export default {
    props: ['client', 'gallery', 'config'],
    components: {
      SplitCarousel,
      SplitCarouselItem
    }
}
</script>