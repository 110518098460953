<template>
    
    <div id="fh5co-quotes" class="fh5co-bg" style="background-image:url(images/img_bg_3.jpg);">
		<div class="overlay2"></div>
		<div class="container">
            
			<div class="row">
				<div class="display-t">
					<div class="display-tc">
						<div class="col-md-10 col-md-offset-1">
							<div style="z-index: 1000;" class="col-md-8 col-md-offset-2 text-center animate-box">
                                <img style="width:auto; margin-bottom:-70px;" src="/assets/images/Asset-6-300x135.png" alt="">
                            </div>
                            <div class="col-md-6 col-sm-6 col-md-offset-3 text-center">
								<div class="event-wrap animate-box">
                                    <p style="margin-top:100px;" v-html="client.quote"></p>
                                    <h5 style="margin-bottom:100px;">{{client.title_quote}}</h5>
                                </div>
							</div>
                            <div class="col-md-8 col-md-offset-2 text-center animate-box">
                                <img style="width:auto; margin-top:-100px;" src="/assets/images/Asset-7-300x135.png" alt="">
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    
</template>

<script>
export default {
    props: ['client'],
}
</script>